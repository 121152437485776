<template>
  <div>
    <div class="level my-4">
      <div class="level-left">
        <template v-if="filter">
          <div class="level-item">
            <b-dropdown
              aria-role="list"
              :close-on-click="false"
              ref="dropdown"
              v-if="currentUser.isAdmin === 1"
            >
              <template #trigger="{ active }">
                <b-button
                  label="Download Report"
                  type="is-primary"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                  icon-left="download"
                  outlined
                />
              </template>
              <div class="dropdown-header">File</div>

              <b-dropdown-item
                v-for="item in statuses"
                :key="item.value"
                aria-role="listitem"
              >
                <b-checkbox
                  v-model="request_statuses"
                  :native-value="item.value"
                >
                  {{ item.title }}
                </b-checkbox>
              </b-dropdown-item>

              <div class="dropdown-footer">
                <b-button
                  label="Download"
                  type="is-primary"
                  @click="downloadReimbursementReport('status')"
                  :loading="isLoadingDownload"
                  :disabled="request_statuses.length < 1"
                  expanded
                />
              </div>
            </b-dropdown>
            <b-button
              v-if="checkedRows.length > 0 && currentUser.isAdmin === 1"
              type="is-primary"
              icon-left="download"
              outlined
              class="ml-3"
              @click="downloadReimbursementReport('id')"
              :loading="isLoadingDownload"
              label="Download"
            />
          </div>
        </template>

        <div v-else class="level-item">
          <b-button
            tag="button"
            class="is-command"
            icon-left="plus"
            @click.native="openModalRequest()"
          >
            New
          </b-button>
        </div>
      </div>

      <div class="level-right">
        <div class="date-picker-container is-align-items-center is-flex">
          <b-datepicker
            v-model="rangeDates"
            :mobile-native="false"
            @input="selectDate($event)"
            class="attendance-datepicker cursor-pointer"
            range
          >
            <template v-slot:trigger>
              <b-button
                v-if="from && to"
                icon-left="calendar-today"
                class="overtime-calendar-btn mr-2"
                icon-right="chevron-down"
              >
                {{ from }}
                <span class="">to</span>
                {{ to }}
              </b-button>

              <b-button
                v-else
                icon-left="calendar-today"
                class="overtime-calendar-btn mr-2"
                icon-right="chevron-down"
              >
                {{ 'Select Date' }}
              </b-button>
            </template>
            <b-button
              label="Clear"
              type="is-danger"
              icon-left="close"
              outlined
              @click="clearDate()"
            />
          </b-datepicker>
        </div>
        <div class="level-item" v-if="search">
          <SearchFilter
            :loadData="lodaData"
            @onSearch="searchIconClick($event)"
          />
        </div>
      </div>
    </div>
    <FilterTabs
      v-if="filter"
      @on-toggle-filter="onToggleFilterStatus($event)"
      :listTab="listTab"
    />
  </div>
</template>

<script>
import FilterTabs from '../FilterContent/TabsFilter.vue'
import SearchFilter from '../FilterContent/SearchFilter.vue'
import axios from 'axios'
import moment from 'moment-timezone'
import { showToast } from '../../services/util'

export default {
  components: {
    FilterTabs,
    SearchFilter,
  },
  data() {
    return {
      activeChanged: false,
      request_statuses: [],
      isLoadingDownload: false,
      statuses: [
        { title: 'Data Waiting', value: 'waiting' },
        { title: 'Data Approved', value: 'approved' },
        { title: 'Data Paid', value: 'paid' },
        { title: 'Data Unpaid', value: 'unpaid' },
        { title: 'Data Declined', value: 'declined' },
        { title: 'Data Cancelled', value: 'cancelled' },
      ],
      listTab: [
        'waiting',
        'approved',
        'paid',
        'unpaid',
        'declined',
        'canceled',
        'all',
      ],
      rangeDates: [],
    }
  },

  props: {
    checkedRows: Array,
    isMassUpdating: Boolean,
    from: String,
    to: String,
    today: String,
    search: Boolean,
    filter: Boolean,
    rightFilters: Boolean,
    open: Boolean,
    pageMenu: String,
    lodaData: Boolean,
    hasCompletedFilter: Boolean,
    currentUser: Object,
  },
  methods: {
    /**
     * Emit mass update method from parent component (not used yet)
     * @param {Object} evt - event listener
     */
    massUpdate(evt) {
      this.$emit('onMassUpdate', evt)
    },

    /**
     * Emit select date method from parent component
     * @param {Object} evt - event listener
     */
    selectDate(evt) {
      this.$emit('onSelectDate', evt)
    },

    /**
     * Clear date
     */
    clearDate() {
      this.$emit('clearDate')
      this.rangeDates = []
    },

    /**
     * open Request Modal
     */
    openModalRequest() {
      this.$emit('addNew')
    },

    /**
     * Toggle Status tab
     * @param {Object} evt - event listener
     */
    onToggleFilterStatus(evt) {
      this.$emit('onToggleFilterStatus', evt)
    },

    /**
     * Search Table data listener
     * @param {Object} evt - event listener
     */
    searchIconClick(evt) {
      this.$emit('onSearch', evt)
    },

    /**
     * Download/Export Reimbursement
     * @param {String} type - Download Filter By
     */
    async downloadReimbursementReport(type) {
      const params = {}

      if (!this.from && !this.to && type === 'status') {
        showToast('Please Select Specific Date First', 'is-danger', 'is-top')
      } else {
        if (this.checkedRows.length > 0 && type === 'id')
          params.approval_ids = this.checkedRows.map((item) => item.id)

        if (this.request_statuses.length > 0 && type === 'status') {
          params.request_statuses = this.request_statuses
          params.start_date = moment(this.from).format('YYYY-MM-DD')
          params.end_date = moment(this.to).format('YYYY-MM-DD')
        }

        try {
          this.isLoadingDownload = true
          let response = await axios.get(
            `/api/space-roketin/reimburse/export`,
            {
              headers: {
                'Content-Disposition':
                  'attachment; filename=Reimbursement Report.xlsx',
                'Content-Type':
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
              responseType: 'blob',
              params,
            }
          )
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Reimbursement Report.xlsx')
          document.body.appendChild(link)
          link.click()
          this.isLoadingDownload = false

          if (type === 'status') this.$refs.dropdown.toggle()

          showToast('Export success', 'is-success', 'is-top')
        } catch (err) {
          showToast('Failed to fetch data', 'is-danger', 'is-top')
          this.isLoadingDownload = false
        }
      }
    },
  },
}
</script>
