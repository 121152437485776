<template>
  <Modal
    :title="'Request Paid'"
    :show="show"
    :class="'modal-allowed-capacity'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline" id="date-picker-reimburse">
            <div class="column is-12">
              <ValidationProvider
                name="Selected Paid Date"
                v-slot="{ errors }"
                rules="required"
                v-if="modalType === 'paid'"
              >
                <b-field
                  label="Select Date"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                  :v-show="isDateOpen"
                >
                  <b-datepicker
                    :rules="modalType === 'paid' ? 'required' : ''"
                    icon-right="calendar"
                    v-model="paid_date"
                    placeholder="Date"
                    trap-focus
                    :min-date="minDate"
                  />
                </b-field>
              </ValidationProvider>

              <ValidationProvider
                name="Unpaid Reason"
                v-slot="{ errors }"
                rules="required"
                v-if="modalType === 'unpaid'"
              >
                <b-field
                  label="Unpaid Reason"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                  class="mb-3"
                >
                  <b-select
                    v-model="selectedUnpaid"
                    expanded
                    placeholder="Select Unpaid"
                    class="hover"
                  >
                    <option
                      v-for="option in listUnpaid"
                      :value="option"
                      :key="option"
                    >
                      {{ option }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>

              <ValidationProvider
                name="Other Reason"
                v-slot="{ errors }"
                rules="required"
                v-if="modalType === 'unpaid' && selectedUnpaid === 'Other'"
                class="mt-2"
              >
                <b-field
                  label="Or write other reason"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    type="textarea"
                    v-model="otherReason"
                    placeholder="Enter reason"
                  />
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <b-button
                expanded
                type="is-primary"
                :loading="loadingSubmit"
                @click="onSubmit"
                :disabled="
                  (selectedUnpaid === 'Other' && otherReason.length < 1) ||
                  (modalType === 'paid' && !paid_date)
                "
              >
                {{ modalType === 'paid' ? 'Paid' : 'Confirm' }}
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  data() {
    const today = new Date()
    return {
      paid_date: null,
      selectedUnpaid: 'Unclear bill (blurry, cut off)',
      listUnpaid: [
        'Unclear bill (blurry, cut off)',
        'The amount is not in accordance with the requested',
        'The reimbursement description is not accurate',
        'Other',
      ],
      otherReason: '',
      isDateOpen: true,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: '',
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Reset form state.
     */
    resetState() {
      this.paid_date = ''
      this.selectedUnpaid = 'Unclear bill (blurry, cut off)'
      this.otherReason = ''
    },
    /**
     * Emit hide modal component.
     */
    hide() {
      this.resetState()
      this.$emit('hide')
    },

    resetPaymentState() {
      this.paid_date = null
      this.response_note = ''
      this.selectedUnpaid = 'Unclear bill (blurry, cut off)'
      this.otherReason = ''
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit', {
        status: this.modalType,
        paid_date: this.paid_date,
        response_note:
          this.selectedUnpaid !== 'Other'
            ? this.selectedUnpaid
            : this.otherReason,
      })
      setTimeout(() => this.resetPaymentState(), 2000)
    },

    /**
     * Emit fetch user list.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     */
    selectUser() {
      this.$emit('selectUser')
    },

    /**
     * Emit get more user list.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },

    /**
     * Emit open file url.
     */
    openFileUrl(fileUrl) {
      this.$emit('openFileUrl', fileUrl)
    },

    /**
     * Emit delete drop file.
     */
    deleteDropFile() {
      this.$emit('deleteDropFile')
    },
  },
}
</script>
