<template>
  <Modal
    :title="'Reimbursement Request'"
    :show="show"
    :class="'modal-log-activity-management'"
    :isSubmit="false"
    :isCancel="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <div class="columns is-multiline has-text-color-label">
        <div class="column is-9 is-flex is-align-items-center">
          <img
            class="selected-time-off-approval-profile-picture"
            :src="
              determineProfilePicture(
                selectedReimbursement.requestedUser.profilePictureUrl
              )
            "
          />
          <div>
            <span
              class="time-off-approval-modal-content-full-name has-text-weight-semibold"
            >
              {{ selectedReimbursement.requestedUser.fullName }}
            </span>
            <br />
            <span class="time-off-approval-modal-content-position-name">
              {{ selectedReimbursement.requestedUser.position }}
            </span>
          </div>
        </div>
        <div
          class="column is-3 is-flex is-align-items-center is-justify-content-flex-end"
        >
          <div
            :class="`time-off-approval-modal-status-container has-text-white has-text-weight-semibold ${determineBGClassByStatus(
              selectedReimbursement.approvalStatus
            )}`"
          >
            {{
              selectedReimbursement.reimburseStatus === 'completed'
                ? selectedReimbursement.reimburseStatus
                : selectedReimbursement.approvalStatus
            }}
          </div>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Reimbursement Type
            <br />
            <span class="has-text-weight-normal">
              {{ selectedReimbursement.reimburseTypeName }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Date
            <br />
            <span class="has-text-weight-normal">
              {{ formatDate(selectedReimbursement.date) }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Reimbursement Amount
            <br />
            <span class="has-text-weight-normal">
              {{
                selectedReimbursement.totalReimburse &&
                `Rp ${selectedReimbursement.totalReimburse.toLocaleString()}`
              }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Request Reason
            <br />
            <span class="has-text-weight-normal">
              {{ selectedReimbursement.requestReason }}
            </span>
          </p>
        </div>

        <div
          class="column is-12"
          v-if="selectedReimbursement.reimburseStatus === 'unpaid'"
        >
          <p class="has-text-weight-bold">
            Unpaid Reason
            <br />
            <span class="has-text-weight-normal">
              {{
                selectedReimbursement.reimbursementPayment.responseNote || '-'
              }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold mb-2">File</p>
          <div class="tags">
            <span
              v-for="file in selectedReimbursement.files"
              :key="file.id"
              class="tag is-primary tag-small"
            >
              <div class="file-tag" @click="openFileUrl(file.url)">
                {{ file.name }}
              </div>
            </span>
          </div>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Approved By
            <br />
            <TimeOffApprovalHistoryComponent
              class="has-text-color-label"
              :approval-history="selectedReimbursement.history"
            />
          </p>
        </div>

        <div
          class="column is-12"
          v-if="selectedReimbursement.reimbursementPayment !== null"
        >
          <p class="has-text-weight-bold">Paid By</p>
          <div class="approval-history mt-3">
            <vue-timeline-update
              :title="
                selectedReimbursement.reimbursementPayment.paidBy.positionName
              "
              :category="selectedReimbursement.reimbursementPayment.status"
              :description="
                formatDescription(
                  selectedReimbursement.reimbursementPayment.paidBy.fullName
                )
              "
              icon="code"
              :color="
                determineStatusColor(
                  selectedReimbursement.reimbursementPayment.status
                )
              "
              :date="
                selectedReimbursement.reimbursementPayment.paidDate
                  ? new Date(
                      selectedReimbursement.reimbursementPayment.paidDate
                    )
                  : new Date(selectedReimbursement.updatedAt)
              "
              :dateString="
                getDateString(selectedReimbursement.reimbursementPayment)
              "
            />
          </div>
        </div>

        <div
          class="modal-approval-button-container"
          v-if="selectedReimbursement.approvalStatus === 'waiting'"
        >
          <b-button
            outlined
            class="has-text-weight-bold has-text-black"
            type="is-primary"
            @click="
              updateStatusApproval({
                id: selectedReimbursement.id,
                status: 'declined',
              })
            "
            :disabled="disableDeclined || isUpdatingDeclined"
          >
            {{ isUpdatingDeclined ? 'Declining...' : 'Decline' }}
          </b-button>
          <b-button
            expanded
            type="is-primary"
            class="has-text-weight-bold"
            @click="
              updateStatusApproval({
                id: selectedReimbursement.id,
                status: 'approved',
              })
            "
            :disabled="disableAccepted || isUpdatingAccepted"
          >
            {{ isUpdatingAccepted ? 'Approving...' : 'Approve' }}
          </b-button>
        </div>
        <div
          class="modal-approval-button-container"
          v-if="
            selectedReimbursement.reimbursementPayment !== null &&
            selectedReimbursement.reimburseStatus === 'approved' &&
            currentUser.id ===
              selectedReimbursement.reimbursementPayment.paidBy.userId
          "
        >
          <b-button
            expanded
            outlined
            class="has-text-weight-bold has-text-black"
            type="is-primary"
            @click="openModalPayment('unpaid')"
            :disabled="disableDeclined || isUpdatingDeclined"
          >
            Unpaid
          </b-button>
          <b-button
            expanded
            type="is-primary"
            class="has-text-weight-bold"
            @click="openModalPayment('paid')"
            :disabled="disableAccepted || isUpdatingAccepted"
          >
            Paid
          </b-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
//component
import moment from 'moment'
import { determineBackgroundColorByStatus } from '@/services/util'
import Modal from '../../../components/Modals/Modal.vue'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { Modal, TimeOffApprovalHistoryComponent },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedReimbursement: {
      type: Object,
      default: null,
    },
    userApproval: {
      type: Array,
      default: null,
    },
    disableDeclined: {
      type: Boolean,
      default: false,
    },
    disableAccepted: {
      type: Boolean,
      default: false,
    },
    disableCanceled: {
      type: Boolean,
      default: false,
    },
    isUpdatingAccepted: {
      type: Boolean,
      default: false,
    },
    isUpdatingDeclined: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit complete approval.
     */
    completeApproval(event) {
      this.$emit('completeApproval', event)
    },

    /**
     * Emit Update Status Approval.
     */
    updateStatusApproval({ id, status }) {
      const param = {
        id,
        status,
      }
      this.$emit('updateStatusApproval', param)
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      return moment(str).format('DD MMMM YYYY')
    },

    /**
     * Set Session format
     * @param {integer} data - ID session
     */
    formatSession(number) {
      switch (number) {
        case 1:
          return 'Morning'

        case 2:
          return 'Afternoon'

        default:
          return 'Full Day'
      }
    },

    /**
     * Set WFO Session css class
     * @param {integer} data - id session
     * @return {String} session class name
     */
    formatSessionClass(number) {
      switch (number) {
        case 1:
          return 'session-morning'

        case 2:
          return 'session-afternoon'

        default:
          return 'session-full-day'
      }
    },

    /**
     * Set WFO Session css class
     * @param {integer} data - id session
     * @return {String} session class name
     */
    openModalPayment(type) {
      this.$emit('openModalPayment', type)
    },

    /**
     * Determine Profile picture from database
     * @param {String} picture - Picture URL
     * @param {String} gender - gender string
     * used if no picture is present ('F'/ 'M')
     * @return {String} default gender picture url
     */
    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      }

      return picture
    },

    /**
     * Get date string for vue timeline
     * @param {Object} data - Picture URL
     * @return {String} formatted date string / -
     */
    getDateString(data) {
      switch (data.status) {
        case 'paid':
          return this.formatDate(data.paidDate)
        case 'unpaid':
          return this.formatDate(data.unpaidDate)
        default:
          return '-'
      }
    },

    /**
     * Format Description
     * @param {String} name
     * @param {String} date
     * @return {String} formatted date string / -
     */
    formatDescription(name, date) {
      let fullName = name
      if (!date) {
        return fullName
      }
    },

    /**
     * Determine color by status
     * @param {String} status - status reimbursement
     * @return {String} color
     */
    determineStatusColor(status) {
      switch (status) {
        case 'paid':
          return 'green'
        case 'unpaid':
          return 'red'
        default:
          return 'blue'
      }
    },

    /**
     * Open File on mouse click
     * @param {string} fileUrl - File url from database
     */
    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    /**
     * Get Reimbursement Image URL
     * @param {Object} item - Image object from database
     * @return {String} Image URL
     */
    getImageURL(item) {
      if (item?.[0]?.url) {
        return process.env.VUE_APP_API_URL + item[0].url
      }
      return false
    },
  },
}
</script>
