<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Reimbursement">
      <template #page-header>
        <h2 class="page-title">Reimbursement</h2>
        <r-menu-tab :level="3" />
      </template>

      <template #action-bar>
        <ReimbursementHeader
          :filter="true"
          :search="true"
          :from="start_date"
          :to="end_date"
          :today="toDate"
          :checkedRows="checkedRows"
          :isMassUpdating="isMassUpdating"
          @onMassUpdate="massUpdate"
          @onSelectDate="selectDate"
          @clearDate="clearDate"
          :pageMenu="'request'"
          @onToggleFilterStatus="onToggleFilterStatus"
          :lodaData="isReimbursementListLoading"
          @onSearch="searchIconClick($event)"
          :hasCompletedFilter="true"
          :currentUser="currentUser"
          :rangeDates="rangeDates"
        />
      </template>

      <template #page-content>
        <div id="table-request-employment" class="table-timeoff-type">
          <b-table
            :data="reimbursementTeam"
            :per-page="perPage"
            :loading="isReimbursementListLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="3%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>

              <b-table-column
                field="full_name"
                label="Full Name"
                v-slot="props"
                sortable
                width="18%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="log-activity-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.requestedUser.profilePictureUrl,
                        props.row.requestedUser.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.requestedUser.fullName }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="reimburse_type_name"
                label="Type of Reimburse"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{ props.row.reimburseTypeName }}
                </span>
              </b-table-column>

              <b-table-column
                field="date"
                label="Date"
                v-slot="props"
                sortable
                width="11%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="total_reimburse"
                label="Amount"
                v-slot="props"
                sortable
                width="12%"
              >
                <span class="is-capitalize">
                  {{
                    props.row.totalReimburse &&
                    `Rp ${props.row.totalReimburse.toLocaleString()}`
                  }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{ props.row.requestReason }}
                </span>
              </b-table-column>

              <b-table-column
                field="paidDate"
                label="Paid Date"
                :visible="status === 'paid'"
                v-slot="props"
                width="10%"
              >
                {{ formatDate(props.row.reimbursementPayment.paidDate) }}
              </b-table-column>

              <b-table-column
                field="responseNote"
                label="Unpaid Reason"
                :visible="status === 'unpaid'"
                v-slot="props"
                width="20%"
              >
                {{ props.row.reimbursementPayment.responseNote || '-' }}
              </b-table-column>

              <b-table-column
                field="reimburse_type_user.status"
                label="Status"
                v-slot="props"
                sortable
                width="7%"
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.reimburseStatus)}`"
                >
                  {{
                    props.row.isCompleted
                      ? 'completed'
                      : props.row.reimburseStatus
                  }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="created_at"
                label="Created At"
                sortable
                width="11%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>

              <b-table-column label="Action" v-slot="props" width="5%">
                <b-icon
                  type="is-primary"
                  :icon="
                    ['waiting', 'approved'].includes(props.row.reimburseStatus)
                      ? 'pencil'
                      : 'eye'
                  "
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalDetail(props.row)"
                ></b-icon>
                <b-icon
                  v-if="
                    props.row.reimburseStatus === 'approved' &&
                    props.row.reimbursementPayment === null &&
                    props.row.finalApproverId === currentUser.id
                  "
                  type="is-primary"
                  icon="check"
                  custom-size="mdi-18px"
                  class="employment-edit-icon pointer-click"
                  @click.native="
                    completeApproval(props.row.requestReimbursementId)
                  "
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <ReimbursementApprovalModal
          :show="isModalOpen"
          :selectedReimbursement="selectedReimbursement"
          :userApproval="userApproval"
          :disableDeclined="disableDeclined"
          :isUpdatingDeclined="isUpdatingDeclined"
          :disableAccepted="disableAccepted"
          :isUpdatingAccepted="isUpdatingAccepted"
          :disableCanceled="disableCanceled"
          :currentUser="currentUser"
          @hide="closeModalEdit"
          @updateStatusApproval="updateStatusApproval"
          @completeApproval="completeApproval($event)"
          @openModalPayment="openModalPayment($event)"
        />

        <ReimbursementPaymentModal
          :show="isModalPaymentOpen"
          :modalType="modalPaymentType"
          :loadingSubmit="isLoadingPayment"
          @hide="closeModalPayment"
          @submit="onSubmitPayment($event)"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  determineBackgroundColorByStatus,
  determineFontColorByStatus,
} from '@/services/util'
import ReimbursementHeader from '../../../components/Header/ReimbursementHeader.vue'
import ReimbursementApprovalModal from './ReimbursementApprovalModal.vue'
import ReimbursementPaymentModal from './ReimbursementPaymentModal.vue'

export default {
  components: {
    ReimbursementHeader,
    ReimbursementApprovalModal,
    ReimbursementPaymentModal,
  },
  data() {
    return {
      moduleType: 4, // Reimbursement
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      checkedRows: [],

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',
      start_date: '',
      end_date: '',

      isReimbursementListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,

      selectedReimbursement: null,

      isUpdating: false,
      isUpdatingAccepted: false,
      isUpdatingDeclined: false,
      disableDeclined: false,
      disableAccepted: false,
      disableCanceled: false,
      isMassUpdating: false,
      isLoadingPayment: false,

      rangeDates: [],

      // payment modal
      isModalPaymentOpen: false,
      modalPaymentType: '',
      paid_date: '',
      selectedUnpaid: 'Unclear bill (blurry, cut off)',
      listUnpaid: [
        'Unclear bill (blurry, cut off)',
        'The amount is not in accordance with the requested',
        'The reimbursement description is not accurate',
        'Other',
      ],
      otherReason: '',

      // filter by date
      dates: [],
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedStatus: 'selectedStatus',
      status: 'waiting',
    }
  },

  computed: {
    ...mapGetters({
      reimbursementTeam: 'reimbursement/getReimbursementApprovalData',
      currentUser: 'auth/currentUser',
      userApproval: 'finalApproval/getUserApproval',
      reimbursementApprovalDetail:
        'reimbursement/getReimbursementApprovalDetail',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadReimbursementTeam:
        'reimbursement/fetchReimbursementApprovalData',
      actionUpdateStatusApproval: 'reimbursement/updateReimbursementApproval',
      actionMassUpdateStatusApproval:
        'reimbursement/massUpdateReimbursementApproval',
      actionFetchUserApproval: 'finalApproval/fetchUserApproval',
      actionFetchReimbursementApprovalDetail:
        'reimbursement/fetchReimbursementApprovalDetail',
      actionCompleteApproval: 'reimbursement/completeReimbursementApproval',
      actionCompletePayment: 'reimbursement/completeReimbursementPayment',
    }),

    ...mapMutations({
      setReimbursementApprovalData:
        'reimbursement/setReimbursementApprovalData',
      setReimbursementApprovalDetail:
        'reimbursement/setReimbursementApprovalDetail',
    }),

    /**
     * Clear date detail
     */
    async clearDate() {
      this.start_date = null
      this.end_date = null
      this.rangeDates = []
      await this.resetState()
    },

    /**
     * Select Date Range of table data
     * @param {Date} val - selected date range
     */
    async selectDate(val) {
      this.rangeDates = val
      this.start_date = moment(val[0]).format('YYYY-MM-DD')
      this.end_date = moment(val[1]).format('YYYY-MM-DD')

      await this.resetState()
    },

    /**
     * Toggle Filter Status
     * @param {Array} filterStatus - Array of status filter
     */
    async onToggleFilterStatus(filterStatus) {
      this.selectedStatus = filterStatus?.length < 1 ? '' : 'selectedStatus'
      this.status = filterStatus
      this.checkedRows = []
      await this.resetState()
    },

    /**
     * Determine Profile picture from database
     * @param {String} picture - Picture URL
     * @param {String} gender - gender string
     * used if no picture is present ('F'/ 'M')
     * @return {String} default gender picture url
     */
    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      }

      return picture
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * create manual reimbursement payment
     * @param {integer} id - Selected Id from table
     */
    async completeApproval(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `are you sure want to process this request to next step ? You can't undo this action`,
      })

      if (res && res.isConfirmed) {
        this.disableCanceled = true

        try {
          const payload = {
            request_reimburse_id: id,
          }
          await this.actionCompleteApproval(payload)
          showToast(
            'Request Reimbursement Payment sucessfully created!',
            'is-success',
            'is-top'
          )
          await this.onToggleFilterStatus(this.status)
        } catch (err) {
          console.log(err)
          showToast(err.response.data.meta.message, 'is-danger', 'is-top')
        }
      }
    },

    /**
     * load more table data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadReimbursementTeamList()
        this.isLoadMore = false
      }
    },

    /**
     * Reset state of table
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setReimbursementApprovalData([])
      await this.loadReimbursementTeamList(searchInput)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadReimbursementTeamList()
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      this.resetState(searchInput)
    },

    /**
     * Load reimbursement management list data.
     * @param {String} searchInput - keyword search (optional)
     */
    async loadReimbursementTeamList(searchInput) {
      this.isReimbursementListLoading = true

      try {
        const response = await this.actionLoadReimbursementTeam({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
          start_date: this.start_date,
          end_date: this.end_date,
          selectedStatus: this.selectedStatus,
          status: this.status,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isReimbursementListLoading = false
    },

    /**
     * Open Detail Modal. If id present,
     * set form to include data from selected table id
     * @param {integer} row - table row index (optional)
     * @param {integer} id - selected TimeOff id from table
     */
    async openModalDetail(row, id) {
      if (id) {
        let response = await this.actionFetchReimbursementApprovalDetail(id)
        this.selectedReimbursement = response.data.data
      } else {
        this.selectedReimbursement = row
      }

      this.isModalOpen = true
    },

    /**
     * Open Payment Modal. If id present,
     * set form to include data from selected table id
     * @param {integer} row - table row index (optional)
     * @param {integer} id - selected TimeOff id from table
     */
    async openModalPayment(type) {
      this.modalPaymentType = type
      this.isModalPaymentOpen = true
    },

    /**
     * Close Edit Modal
     */
    closeModalEdit() {
      this.selectedReimbursement = null
      this.isModalOpen = false
    },

    /**
     * Close Edit Modal
     */
    closeModalPayment() {
      this.isModalPaymentOpen = false
      this.modalPaymentType = ''
    },

    /**
     * Update multiple request status
     * @param {Object} status object
     */
    async massUpdate(status) {
      let ids = []

      this.checkedRows.forEach((c) => ids.push(c.id))

      // trigger loading animation
      this.isMassUpdating = true

      try {
        await this.actionMassUpdateStatusApproval({ status, ids })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }
      this.isMassUpdating = false
      this.checkedRows = []
    },

    /**
     * Handle submit payment reimbursement
     * @param {Object} data object
     */
    async onSubmitPayment(data) {
      try {
        this.isLoadingPayment = true
        const payload = {
          status: data.status,
          id: this.selectedReimbursement.requestReimbursementId,
          paid_date: data.paid_date
            ? moment(data.paid_date).format('YYYY-MM-DD')
            : '',
          response_note: data.response_note,
        }

        await this.actionCompletePayment(payload)

        showToast('Update Success', 'is-success', 'is-top')

        this.closeModalPayment()

        this.closeModalEdit()

        await this.onToggleFilterStatus(this.status)

        this.isLoadingPayment = false
      } catch (err) {
        console.log(err)
        showToast(err.response.data.meta.message, 'is-warning', 'is-top')

        this.isLoadingPayment = false
      }
    },

    /**
     * Update Approval Status
     * @param {integer} id - selected data id
     * @param {String} status - selected data id
     */
    async updateStatusApproval(param) {
      const { id, status } = param
      const currentUserId = this.currentUser.id

      if (status === 'declined') {
        this.disableAccepted = true
        this.isUpdatingDeclined = true
      } else {
        this.disableDeclined = true
        this.isUpdatingAccepted = true
      }

      try {
        const res = await this.actionUpdateStatusApproval({
          id,
          status,
          reportToId: currentUserId,
        })

        if (res.data.approval.status === 'approved')
          await this.actionCompleteApproval({
            request_reimburse_id: res.data.approval.reimburseTypeUserId,
          })

        this.closeModalEdit()

        showToast('Update Success', 'is-success', 'is-top')

        this.onToggleFilterStatus(this.status)
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }

      this.closeModalEdit()

      if (status === 'declined') {
        this.disableAccepted = false
        this.isUpdatingDeclined = false
      } else {
        this.disableDeclined = false
        this.isUpdatingAccepted = false
      }
    },
  },
  async mounted() {
    this.setReimbursementApprovalData([])
    await this.loadReimbursementTeamList()
    if (this.$route.query.id) {
      await this.openModalDetail(null, this.$route.query.id)
    }
  },
}
</script>
